<template>
  <div class="main_right">
      <div class="Box">
        <iframe src="https://chat.fefsfc.com" frameborder="0" class="iframe" width="100%" height="100%"></iframe>
      </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      message: '',
      messages: []
    }
  },
  methods:{
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
.main_right{
  width: calc(100% - 10.5vw);
  height: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0 2%;
  position: relative;
  .Box{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 16px 0px rgba(89, 144, 255, 0.3);
    border-radius: 10px;
    padding: 0.1%;
    box-sizing: border-box;
    position: relative;
    .iframe{
      border: 0;
    }
  }
}
</style>
<style lang="scss">
</style>